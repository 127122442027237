import React from "react"
import { PropTypes } from "prop-types"
import { Pagination } from "react-bootstrap"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { isEmpty } from "lodash-es"
import classNames from "classnames"

import "../styles/pager.scss"

const Pager = ({ pathPrefix, previousPagePath, nextPagePath, humanPageNumber: currentPage, numberOfPages, pageLimit }) => {
  const location = useLocation()
  const path = pathPrefix || `/${location.pathname.split("/")[1]}`
  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1)
  }

  const jumpToNextPageGroup = currentPage + pageLimit
  const jumpToPreviousPageGroup = currentPage - pageLimit

  return numberOfPages > 1 ? (
    <Pagination>
      {/* <Pagination.First disabled={currentPage === 1} onClick={() => navigate(path)} /> */}
      <Pagination.Prev disabled={isEmpty(previousPagePath)} onClick={() => navigate(previousPagePath)} />
      <Pagination.Item
        disabled={currentPage === 1}
        onClick={() => navigate(path)}
        className={classNames({ active: currentPage === 1 })}
      >
        {1}
      </Pagination.Item>
      {jumpToPreviousPageGroup >= 1 && (
        <Pagination.Ellipsis
          disabled={jumpToPreviousPageGroup < 1}
          onClick={() => navigate(`${path}/${jumpToPreviousPageGroup}`)}
        />
      )}
      {getPaginationGroup().map((pageNumber) =>
        pageNumber < numberOfPages && pageNumber !== 1 ? (
          <Pagination.Item
            disabled={currentPage === pageNumber}
            className={classNames({ active: currentPage === pageNumber })}
            key={pageNumber}
            onClick={() => navigate(pageNumber !== 1 ? `${path}/${pageNumber}` : path)}
          >
            {pageNumber}
          </Pagination.Item>
        ) : null
      )}
      {jumpToNextPageGroup <= numberOfPages && (
        <Pagination.Ellipsis
          disabled={jumpToNextPageGroup > numberOfPages}
          onClick={() => navigate(`${path}/${jumpToNextPageGroup}`)}
        />
      )}
      <Pagination.Item
        disabled={currentPage === numberOfPages}
        onClick={() => navigate(`${path}/${numberOfPages}`)}
        className={classNames({ active: currentPage === numberOfPages })}
      >
        {numberOfPages}
      </Pagination.Item>
      <Pagination.Next disabled={isEmpty(nextPagePath)} onClick={() => navigate(nextPagePath)} />
      {/* <Pagination.Last disabled={currentPage === numberOfPages} onClick={() => navigate(`${path}/${numberOfPages}`)} /> */}
    </Pagination>
  ) : null
}

Pager.propTypes = {
  humanPageNumber: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  previousPagePath: PropTypes.string,
  nextPagePath: PropTypes.string,
  pageLimit: PropTypes.number,
  pathPrefix: PropTypes.string,
}

Pager.defaultProps = {
  previousPagePath: "",
  nextPagePath: "",
  pageLimit: 5,
}

export default Pager
