import React from "react"
import Card from "./card"
import { formatDateTime } from "../utils/ug-utils"

const EventTeaser = ({ node }) => {
  const event_location = node.field_location?.summary ?? ""
  const date = formatDateTime(node.field_date.value)
  const type = node.field_event_type
  const highlight = node.field_event_highlight
  const imageData = node.relationships._card_image?.relationships.field_media_image
  const image = {
    gatsbyImageData: imageData?.localFile.childImageSharp.gatsbyImageData,
    alt: imageData?.alt || "Event Image", // Provide a fallback alt text if alt is not defined
  }
  const url = node?.path?.alias || "#"

  return (
    <Card
      cardStyle="aad-standard"
      title={node.title}
      subtitle={event_location}
      url={url}
      image={image}
      bgImageOpacity="100"
      type={type}
      highlight={highlight}
      date={date}
      duration={node.field_webinar_video_duration}
      link_class={type}
      className="event-teaser-card"
    />
  )
}

export default EventTeaser
