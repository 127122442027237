import React from "react"
import PropTypes from "prop-types"
import Facet from "./facet"

const EventsNav = ({ selected }) => {
  const navItems = []
  // Add an "All" option to the webinar categories.
  navItems.push({ id: "all", name: "All Events", url: "/events" })
  navItems.push({ id: "type/webinar", name: "Webinars", url: "/events/type/webinar" })
  navItems.push({ id: "type/general", name: "In Person Events", url: "/events/type/general" })
  navItems.push({ id: "tags/AlumniWeekend", name: "Alumni Weekend", url: "/events/tags/alumniweekend" })
  navItems.push({ id: "tags/Learning", name: "Learning Events", url: "/events/tags/learning" })
  navItems.push({ id: "tags/Networking", name: "Networking Events", url: "/events/tags/networking" })
  navItems.push({ id: "type/webinar/past", name: "Past Webinars", url: "/events/type/webinar/dates/past" })
  navItems.push({ id: "type/general/past", name: "Past Events", url: "/events/type/general/dates/past" })

  return (
    <nav className="events-nav" aria-label="Event Categories">
      <Facet
        type="list"
        data={navItems}
        useHashtag={false}
        className=""
        linkClassName="btn btn-outline-info"
        linkSelectedClassName="btn btn-info"
        selected={selected}
      />
    </nav>
  )
}

EventsNav.propTypes = {
  selected: PropTypes.string,
}

export default EventsNav
